import { API_BASE_URL } from './api/api_base_url';
import React, { FC, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './auth/auth_context';
import axios from 'axios';
import {
  getUserSettingsNames,
  loadUserSettings,
  deleteUserSettings,
  saveUserSettings,
} from './api/settings_api_requests';
import {
  Stack,
  MessageBar,
  MessageBarType,
  TextField,
  PrimaryButton,
  mergeStyles,
  IChoiceGroupOption,
  IComboBoxOption,
  IDropdownOption,
  Spinner,
  SpinnerSize,
  Toggle,
  SpinButton,
  IconButton,
  Label,
  IStackStyles,
} from '@fluentui/react';
import DeleteConfirmationModal from './ui/delete_confimation_modal';
import CustomComboBox from './ui/combobox';
import CustomChoiceGroup from './ui/choice_group';
import CustomCheckBox from './ui/checkbox';
import CustomDatePicker from './ui/date_picker';
import DynamicTextfield from './ui/dynamic_textfield';
import DynamicTextfieldSlider from './ui/dynamic_textfield_slider';
import PromptTextfield from './ui/prompt_textfield';
import InfoLabel from './ui/info_label';
import MultiselectDropdown from './ui/multiselect_dropdown';
import { defaultKwePrompt, defaultRagPrompt } from './prompts/prompts';
import styles from './Component.module.css';
import {
  searchHeaderDiv,
  searchSettingsDiv,
  searchSettingsPromptDiv,
  stackSearchSettings,
  buttonStyleResetSetting,
  searchSaveSettingsDiv,
  buttonStyleSaveSetting,
  searchLoadSettingsDiv,
  searchTextFieldStackStyle,
  textFieldNewSearchSetting,
  buttonStyleLoadDelSavedSetting,
  buttonStyleExpander,
  buttonSettingsDetailsStyle,
  stackExpanderField,
  buttonStyleSearch,
  stackSearchField,
  toggleStyles,
  textFieldItem,
  buttonItem,
  textFieldStyleSearch,
  expanderDivStyle,
  expanderSettingDetailsStyle,
  labelSettingDetailsStyle,
  geospatialTextfieldStyle,
  spinButtonStyle,
  spinButtonProjectMaxDistanceStyle,
  messageBarTagsStyle,
  spinnerStyle,
  searchSettingsDivSmall,
  stackSearchSettingsSecondGroup,
} from './react_styles';
import {
  dataSourceTip,
  searchTypeTip,
  ragTip,
  searchModeTip,
  queryTypeTip,
  maxResultsTip,
  availStartDateTip,
  staffingStatusTip,
  staffingPriorityTip,
  orgUnitTip,
  careerLevelTip,
  careerTrackTip,
  projectLocationDistanceTip,
  projectAbsencesTip,
  entryDateAfterTip,
  lastModifiedDateOlderTip,
  skillTip,
  kweContextPromptTip,
  ragContextPromptTip,
  furtherFilterTip,
  furtherAdvancedSearchTip,
} from './tool_tips/tool_tips';
import {
  savedSearchSettings,
  searchTypeOptions,
  searchModeOptions,
  queryTypeOptions,
  staffingStatusOptions,
  staffingPriorityOptions,
  orgUnitOptions,
  careerLevelOptions,
  careerTrackOptions,
  maximumSkillSliderLevel,
  sliderSkillLevels,
  defaultSavedSearchSetting,
  defaultSelectedSearchTypeOption,
  defaultToggleRag,
  defaultSelectedSearchModeOption,
  defaultSelectedQueryTypeOption,
  defaultMaxResults,
  defaultSelectedAvDateMin,
  defaultSelectedAvDateMax,
  defaultCheckedStateStaffingStatus,
  defaultCheckedStateStaffingPriority,
  defaultSelectedOrgUnit,
  defaultCheckedStateCareerLevel,
  defaultCheckedStateCareerTrack,
  defaultProjectPostalCode,
  defaultProjectLocation,
  defaultProjectMaxDistance,
  defaultProjectStartDate,
  defaultProjectEndDate,
  defaultProjectAbsenceThreshold,
  defaultEntryDateAfter,
  defaultLastModifiedDateOlder,
  defaultIndustryItems,
  defaultExpertiseItems,
  defaultSkillItems,
  defaultSearchQuery,
  defaultDataSource,
  defaultDataSoruceSimple,
  dataSourceOptions,
  queryTypeOptionsMapping,
} from './defaults/options_defaults';

import { useCase1, useCase2, useCase3 } from './defaults/search_defaults';

interface Props {
  setResults: (data: any) => void;
  setIsRag: (data: boolean) => void;
}

export interface SearchSettings {
  dataSource: IDropdownOption[];
  careerLevel: IDropdownOption[];
  careerTrack: IDropdownOption[];
  staffingStatus: IDropdownOption[];
  searchTypeOption: number;
  toggleRag: boolean;
  searchModeOption: number;
  queryTypeOption: number;
  maxResults: number;
  avDateMin: Date;
  avDateMax: Date;
  staffingPriority: { [key: string]: boolean };
  orgUnit: IDropdownOption[];
  projectPostalCode: string;
  projectLocation: string;
  projectMaxDistance: number;
  projectStartDate: Date;
  projectEndDate: Date;
  projectAbsenceThreshold: number;
  entryDateAfter: Date;
  lastModifiedDateOlder: Date;
  skillItems: SkillItem[];
  searchQuery: string;
  kwePrompt: string;
  ragPrompt: string;
}

interface CheckboxOption {
  key: string;
  text: string;
}

type TextFieldItem = {
  textValue: string;
};

type SkillItem = {
  typeValue: string;
  textValue: string;
  categoryValue?: string;
  sliderValue?: number;
};

const SearchComponent: FC<Props> = ({ setResults, setIsRag }) => {
  // Authentication status
  const { user, checkPermission } = useAuth();

  const handleStatsPageNavigation = () => {
    window.location.href = '#/stats';
  };

  // Loaded states of search settings (ToDo: Loading functionality has to be implemented, currently eqt default)
  //------------------------------------------------------------------------------------------------------------
  const defaultSettings: SearchSettings = {
    dataSource: defaultDataSource,
    careerLevel: defaultCheckedStateCareerLevel,
    careerTrack: defaultCheckedStateCareerTrack,
    staffingStatus: defaultCheckedStateStaffingStatus,
    searchTypeOption: defaultSelectedSearchTypeOption,
    toggleRag: defaultToggleRag,
    searchModeOption: defaultSelectedSearchModeOption,
    queryTypeOption: defaultSelectedQueryTypeOption,
    maxResults: defaultMaxResults,
    avDateMin: defaultSelectedAvDateMin,
    avDateMax: defaultSelectedAvDateMax,
    staffingPriority: defaultCheckedStateStaffingPriority,
    orgUnit: defaultSelectedOrgUnit,
    projectPostalCode: defaultProjectPostalCode,
    projectLocation: defaultProjectLocation,
    projectMaxDistance: defaultProjectMaxDistance,
    projectStartDate: defaultProjectStartDate,
    projectEndDate: defaultProjectEndDate,
    projectAbsenceThreshold: defaultProjectAbsenceThreshold,
    entryDateAfter: defaultEntryDateAfter,
    lastModifiedDateOlder: defaultLastModifiedDateOlder,
    skillItems: defaultSkillItems,
    searchQuery: defaultSearchQuery,
    kwePrompt: defaultKwePrompt,
    ragPrompt: defaultRagPrompt,
  };

  const extendedStackSearchSettings: Partial<IStackStyles> = {
    root: {
      paddingBottom: 20, // Abstand hinzufügen
    },
  };

  // State storing
  //------------------------------------------------------------------------------------------------------------
  const [selectedDataSource, setSelectedDataSource] = useState<
    IDropdownOption[]
  >(defaultSettings.dataSource);
  const [selectedCareerLevel, setSelectedCareerLevel] = useState<
    IDropdownOption[]
  >(defaultSettings.careerLevel);
  const [selectedCareerTrack, setSelectedCareerTrack] = useState<
    IDropdownOption[]
  >(defaultSettings.careerTrack);
  const [checkedStateStaffingStatus, setCheckedStateStaffingStatus] = useState<
    IDropdownOption[]
  >(defaultSettings.staffingStatus);
  const [expandedSearchSettings, setExpandedSearchSettings] =
    useState<boolean>(false);
  const [expandedAdvancedSearchSettings, setExpandedAdvancedSearchSettings] =
    useState<boolean>(false);
  const [expandedDataSourceSettings, setExpandedDataSourceSettings] =
    useState<boolean>(true);
  const [expandedTechnicalSettings, setExpandedTechnicalSettings] =
    useState<boolean>(false);
  const [expandedFilterSettings, setExpandedFilterSettings] =
    useState<boolean>(false);
  const [expandedQuerySettings, setExpandedQuerySettings] =
    useState<boolean>(true);
  const [loadedSearchSetting, setLoadedSearchSetting] =
    useState<SearchSettings>(defaultSettings);
  const [searchSettingsNames, setSearchSettingsNames] =
    useState<IComboBoxOption[]>(savedSearchSettings);
  const [selectedSearchSetting, setSelectedSearchSetting] = useState<
    IComboBoxOption | undefined
  >(defaultSavedSearchSetting);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false);
  const [selectedSearchTypeOption, setSelectedSearchTypeOption] =
    useState<number>(defaultSettings.searchTypeOption);
  const [toggleRag, setToggleRag] = useState<boolean>(
    defaultSettings.toggleRag
  );
  const [selectedSearchModeOption, setSelectedSearchModeOption] =
    useState<number>(defaultSettings.searchModeOption);
  const [selectedQueryTypeOption, setSelectedQueryTypeOption] =
    useState<number>(defaultSettings.searchTypeOption);
  const [maxResults, setMaxResults] = useState<number>(
    defaultSettings.maxResults
  );
  const [selectedAvStartDateMin, setSelectedAvStartDateMin] = useState<Date>(
    defaultSettings.avDateMin
  );
  const [selectedAvStartDateMax, setSelectedAvStartDateMax] = useState<Date>(
    defaultSettings.avDateMax
  );
  const [checkedStateStaffingPriority, setCheckedStateStaffingPriority] =
    useState<{ [key: string]: boolean }>(defaultSettings.staffingPriority);
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<IDropdownOption[]>(
    defaultSettings.orgUnit
  );
  const [selectedProjectPostalCode, setSelectedProjectPostalCode] = useState(
    defaultSettings.projectPostalCode
  );
  const [selectedProjectLocation, setSelectedProjectLocation] = useState(
    defaultSettings.projectLocation
  );
  const [selectedProjectMaxDistance, setSelectedProjectMaxDistance] =
    useState<number>(defaultSettings.projectMaxDistance);
  const [selectedProjectStartDate, setSelectedProjectStartDate] =
    useState<Date>(defaultSettings.projectStartDate);
  const [selectedProjectEndDate, setSelectedProjectEndDate] = useState<Date>(
    defaultSettings.projectEndDate
  );
  const [selectedProjectAbsenceThreshold, setSelectedProjectAbsenceThreshold] =
    useState<number>(defaultSettings.projectAbsenceThreshold);
  const [selectedEntryDateAfter, setSelectedEntryDateAfter] = useState<Date>(
    defaultSettings.entryDateAfter
  );
  const [selectedLastModifiedDateOlder, setSelectedLastModifiedDateOlder] =
    useState<Date>(defaultSettings.lastModifiedDateOlder);
  const [selectedSkillItems, setSelectedSkillItems] = useState<SkillItem[]>(
    defaultSettings.skillItems
  );
  const [kwePrompt, setKwePrompt] = useState(defaultSettings.kwePrompt);
  const [ragPrompt, setRagPrompt] = useState(defaultSettings.ragPrompt);
  const [userQuery, setUserQuery] = useState(defaultSettings.searchQuery);
  const [newSettingName, setNewSettingName] = useState('');
  const [searchSpinner, setSearchSpinner] = useState(false);

  //Permissions
  const [canViewDiamonds, setCanViewDiamonds] = useState(false);
  const [canViewStaffingPriority, setCanViewStaffingPriority] = useState(false);
  const [canViewStatisticEvaluation, setCanViewStatisticEvaluation] =
    useState(false);

  useEffect(() => {
    const checkUserPermission = async () => {
      if (user) {
        //Check permission for diamond
        const hasDiamondsPermission = await checkPermission(
          user.role,
          'CAN_VIEW_DIAMONDS'
        );
        setCanViewDiamonds(hasDiamondsPermission);

        const hasStaffingPriorityPermission = await checkPermission(
          user.role,
          'CAN_VIEW_STAFFING_PRIORITY'
        );
        setCanViewStaffingPriority(hasStaffingPriorityPermission);

        const hasStatisticsPermission = await checkPermission(
          user.role,
          'CAN_VIEW_STATISTIC_EVALUATION'
        );
        setCanViewStatisticEvaluation(hasStatisticsPermission);
        //other permissions
      }
    };
    checkUserPermission();
  }, [user, checkPermission]);

  // Handling change events
  //------------------------------------------------------------------------------------------------------------
  const toggleSearchSettingsExpand = () => {
    setExpandedSearchSettings(!expandedSearchSettings);
  };
  const handleSelectedSearchSetting = (
    selectedItem: IComboBoxOption | undefined,
    index?: number,
    value?: string
  ) => {
    if (selectedItem) {
      setSelectedSearchSetting(selectedItem);
    }
  };
  const resetSearchSetting = () => {
    setSelectedSearchSetting({ key: '', text: '' });
    setSelectedDataSource(defaultDataSource);
    setSelectedCareerLevel(defaultCheckedStateCareerLevel);
    setSelectedCareerTrack(defaultCheckedStateCareerTrack);
    setSelectedSearchTypeOption(defaultSelectedSearchTypeOption);
    setToggleRag(defaultToggleRag);
    setSelectedSearchModeOption(defaultSelectedSearchModeOption);
    setSelectedQueryTypeOption(defaultSelectedQueryTypeOption);
    setMaxResults(defaultMaxResults);
    setSelectedAvStartDateMin(defaultSelectedAvDateMin);
    setSelectedAvStartDateMax(defaultSelectedAvDateMax);
    setCheckedStateStaffingStatus(defaultCheckedStateStaffingStatus);
    setCheckedStateStaffingPriority(defaultCheckedStateStaffingPriority);
    setSelectedOrgUnit(defaultSelectedOrgUnit);
    setSelectedProjectPostalCode(defaultProjectPostalCode);
    setSelectedProjectLocation(defaultProjectLocation);
    setSelectedProjectStartDate(defaultProjectStartDate);
    setSelectedProjectEndDate(defaultProjectEndDate);
    setSelectedProjectAbsenceThreshold(defaultProjectAbsenceThreshold);
    setSelectedProjectMaxDistance(defaultProjectMaxDistance);
    setSelectedEntryDateAfter(defaultEntryDateAfter);
    setSelectedLastModifiedDateOlder(defaultLastModifiedDateOlder);
    setSelectedSkillItems(defaultSkillItems);
    setUserQuery(defaultSearchQuery);
    setKwePrompt(defaultKwePrompt);
    setRagPrompt(defaultRagPrompt);
    setLoadedSearchSetting(defaultSettings);
  };
  const handleDataSourceChange = (updatedSelectedItems: IDropdownOption[]) => {
    setSelectedDataSource(updatedSelectedItems);
  };
  const handleCareerLevelChange = (updatedSelectedItems: IDropdownOption[]) => {
    setSelectedCareerLevel(updatedSelectedItems);
  };
  const handleCareerTrackChange = (updatedSelectedItems: IDropdownOption[]) => {
    setSelectedCareerTrack(updatedSelectedItems);
  };
  const handleStaffingStatusChange = (
    updatedSelectedItems: IDropdownOption[]
  ) => {
    setCheckedStateStaffingStatus(updatedSelectedItems);
  };
  const handleSearchTypeChange = (
    selectedItem: IChoiceGroupOption | undefined,
    index?: number,
    value?: string
  ) => {
    if (selectedItem) {
      setSelectedSearchTypeOption(parseInt(selectedItem.key));
    }
  };
  const handleToggleRag = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked !== undefined) {
      setToggleRag(checked);
    }
  };
  const handleSearchModeChange = (
    selectedItem: IChoiceGroupOption | undefined,
    index?: number,
    value?: string
  ) => {
    if (selectedItem) {
      setSelectedSearchModeOption(parseInt(selectedItem.key));
    }
  };
  const handleQueryTypeChange = (
    selectedItem: IChoiceGroupOption | undefined,
    index?: number,
    value?: string
  ) => {
    if (selectedItem) {
      setSelectedQueryTypeOption(parseInt(selectedItem.key));
    }
  };
  const handleMaxResultsChange = (
    event: React.SyntheticEvent<HTMLElement>,
    newValue?: string
  ) => {
    if (newValue) {
      setMaxResults(parseInt(newValue));
    }
  };
  const handleAvStartDateMin = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1 * updatedDate.getTimezoneOffset()) / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedAvStartDateMin(updatedDate);
    }
  };
  const handleAvStartDateMax = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1 * updatedDate.getTimezoneOffset()) / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedAvStartDateMax(updatedDate);
    }
  };
  const handleStaffingPriorityChange = (checkedOptions: CheckboxOption[]) => {
    const checkedState: { [key: string]: boolean } = {};
    checkedOptions.forEach((option) => {
      checkedState[option.key] = true;
    });
    setCheckedStateStaffingPriority(checkedState);
  };
  const handleOrgUnitChange = (updatedSelectedItems: IDropdownOption[]) => {
    setSelectedOrgUnit(updatedSelectedItems);
  };
  const handleProjectMaxDistanceChange = (
    event: React.SyntheticEvent<HTMLElement>,
    newValue?: string
  ) => {
    if (newValue) {
      setSelectedProjectMaxDistance(parseInt(newValue));
    }
  };
  const handleProjectStartDate = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1 * updatedDate.getTimezoneOffset()) / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedProjectStartDate(updatedDate);
    }
  };
  const handleProjectEndDate = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1 * updatedDate.getTimezoneOffset()) / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedProjectEndDate(updatedDate);
    }
  };
  const handleProjectAbsenceThresholdChange = (
    event: React.SyntheticEvent<HTMLElement>,
    newValue?: string
  ) => {
    if (newValue) {
      setSelectedProjectAbsenceThreshold(parseInt(newValue));
    }
  };
  const handleEntryDate = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1 * updatedDate.getTimezoneOffset()) / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedEntryDateAfter(updatedDate);
    }
  };
  const handleLastModifiedDate = (date: Date | null | undefined) => {
    if (date) {
      const updatedDate = new Date(date);
      const timeZoneOffsetHours = (-1 * updatedDate.getTimezoneOffset()) / 60;
      updatedDate.setHours(updatedDate.getHours() + timeZoneOffsetHours);
      setSelectedLastModifiedDateOlder(updatedDate);
    }
  };
  const handleSkillSearchChange = (
    typeValue: string,
    textValue: string,
    categoryValue: string,
    searchTypeValue: string,
    sliderValue: number,
    index: number
  ) => {
    const updatedSelectedItems = [...selectedSkillItems];
    updatedSelectedItems[index] = {
      typeValue,
      textValue,
      categoryValue,
      sliderValue,
    };
    setSelectedSkillItems(updatedSelectedItems);
  };
  const handleRemoveSkillItem = (index: number) => {
    const updatedSelectedItems = [...selectedSkillItems];
    updatedSelectedItems.splice(index, 1);
    setSelectedSkillItems(updatedSelectedItems);
  };
  const handleKwePromptChange = (textValue: string) => {
    setKwePrompt(textValue);
  };
  const handleRagPromptChange = (textValue: string) => {
    setRagPrompt(textValue);
  };

  const [isStandardDisabled, setIsStandardDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (selectedSearchTypeOption === 1) {
      setIsStandardDisabled(true);
      setSelectedQueryTypeOption(1);

      if (selectedQueryTypeOption === 1) {
        setSelectedQueryTypeOption(1); // Setze auf 'Simple'
      }
    } else {
      setIsStandardDisabled(false);
      setSelectedQueryTypeOption(0);
    }
  }, [selectedSearchTypeOption]);
  // API requests with user inputs and constant updates
  //------------------------------------------------------------------------------------------------------------
  const fetchSettingsNames = async () => {
    const searchSettingNames = await getUserSettingsNames(
      user?.email,
      savedSearchSettings
    );
    setSearchSettingsNames(searchSettingNames);
  };
  useEffect(() => {
    fetchSettingsNames();
  }, []);
  const loadSearchSetting = async () => {
    let errorDetected = false;
    let searchSettings;
    if (
      selectedSearchSetting?.text === 'KI gestützte Ausschreibungsprofil-Suche'
    ) {
      console.log('loading useCase1 settings');
      searchSettings = useCase1;
    } else if (selectedSearchSetting?.text === 'CV Keyword Suche') {
      searchSettings = useCase2;
    } else if (
      selectedSearchSetting?.text === 'Tender/New Projects Mail Suche'
    ) {
      searchSettings = useCase3;
    } else {
      const { userSearchSettings, errorLoading } = await loadUserSettings(
        user?.email,
        selectedSearchSetting?.text,
        defaultSettings
      );
      searchSettings = userSearchSettings;
      errorDetected = errorLoading;
    }
    if (!errorDetected) {
      setLoadedSearchSetting(searchSettings);
      toast.success(
        `Your setting "${selectedSearchSetting?.text}" has been successfully loaded!`
      );
    }
  };

  function setToggleValue<T>(
    setter: (value: T) => void,
    value: T | undefined,
    defaultValue: T
  ) {
    setter(value ?? defaultValue);
  }

  useEffect(() => {
    setToggleValue<IDropdownOption[]>(
      setSelectedDataSource,
      loadedSearchSetting?.dataSource,
      defaultSettings.dataSource
    );
    setToggleValue<IDropdownOption[]>(
      setSelectedCareerLevel,
      loadedSearchSetting?.careerLevel,
      defaultSettings.careerLevel
    );
    setToggleValue<IDropdownOption[]>(
      setSelectedCareerTrack,
      loadedSearchSetting?.careerTrack,
      defaultSettings.careerTrack
    );
    setToggleValue<IDropdownOption[]>(
      setCheckedStateStaffingStatus,
      loadedSearchSetting?.staffingStatus,
      defaultSettings.staffingStatus
    );
    setToggleValue<number>(
      setSelectedSearchTypeOption,
      loadedSearchSetting?.searchTypeOption,
      defaultSettings.searchTypeOption
    );
    setToggleValue<boolean>(
      setToggleRag,
      loadedSearchSetting?.toggleRag,
      defaultSettings.toggleRag
    );
    setToggleValue<number>(
      setSelectedSearchModeOption,
      loadedSearchSetting?.searchModeOption,
      defaultSettings.searchModeOption
    );
    setToggleValue<number>(
      setSelectedQueryTypeOption,
      loadedSearchSetting?.queryTypeOption,
      defaultSettings.queryTypeOption
    );
    setToggleValue<number>(
      setMaxResults,
      loadedSearchSetting?.maxResults,
      defaultSettings.maxResults
    );
    // setToggleValue<Date>(
    //   setSelectedAvStartDateMin,
    //   loadedSearchSetting?.avDateMin,
    //   defaultSettings.avDateMin
    // );
    // setToggleValue<Date>(
    //   setSelectedAvStartDateMax,
    //   loadedSearchSetting?.avDateMax,
    //   defaultSettings.avDateMax
    // );
    setCheckedStateStaffingPriority(
      loadedSearchSetting?.staffingPriority ?? defaultSettings.staffingPriority
    );
    setToggleValue<IDropdownOption[]>(
      setSelectedOrgUnit,
      loadedSearchSetting?.orgUnit,
      defaultSettings.orgUnit
    );
    setToggleValue<string>(
      setSelectedProjectPostalCode,
      loadedSearchSetting?.projectPostalCode,
      defaultSettings.projectPostalCode
    );
    setToggleValue<string>(
      setSelectedProjectLocation,
      loadedSearchSetting?.projectLocation,
      defaultSettings.projectLocation
    );
    setToggleValue<number>(
      setSelectedProjectMaxDistance,
      loadedSearchSetting?.projectMaxDistance,
      defaultSettings.projectMaxDistance
    );
    setToggleValue<Date>(
      setSelectedProjectStartDate,
      loadedSearchSetting?.projectStartDate,
      defaultSettings.projectStartDate
    );
    setToggleValue<Date>(
      setSelectedProjectEndDate,
      loadedSearchSetting?.projectEndDate,
      defaultSettings.projectEndDate
    );
    setToggleValue<number>(
      setSelectedProjectAbsenceThreshold,
      loadedSearchSetting?.projectAbsenceThreshold,
      defaultSettings.projectAbsenceThreshold
    );
    setToggleValue<Date>(
      setSelectedEntryDateAfter,
      loadedSearchSetting?.entryDateAfter,
      defaultSettings.entryDateAfter
    );
    setSelectedSkillItems(
      loadedSearchSetting?.skillItems.map((item) => ({ ...item })) ??
        defaultSettings.skillItems.map((item) => ({ ...item }))
    );
    setUserQuery(
      loadedSearchSetting?.searchQuery ?? defaultSettings.searchQuery
    );
    setKwePrompt(loadedSearchSetting?.kwePrompt ?? defaultSettings.kwePrompt);
    setRagPrompt(loadedSearchSetting?.ragPrompt ?? defaultSettings.ragPrompt);
  }, [loadedSearchSetting]);

  const deleteSearchSetting = async () => {
    const { errorDelete } = await deleteUserSettings(
      user?.email,
      selectedSearchSetting?.text
    );
    if (!errorDelete) {
      setSelectedSearchSetting(defaultSavedSearchSetting);
    }
    setShowDeleteConfirmationModal(false);
    await fetchSettingsNames();
  };
  const saveSearchSettings = async () => {
    try {
      await saveUserSettings(user?.email, newSettingName, {
        dataSource: selectedDataSource,
        careerLevel: selectedCareerLevel,
        careerTrack: selectedCareerTrack,
        searchTypeOption: selectedSearchTypeOption,
        toggleRag: toggleRag,
        searchModeOption: selectedSearchModeOption,
        queryTypeOption: selectedQueryTypeOption,
        maxResults: maxResults,
        avDateMin: selectedAvStartDateMin,
        avDateMax: selectedAvStartDateMax,
        staffingStatus: checkedStateStaffingStatus,
        staffingPriority: checkedStateStaffingPriority,
        orgUnit: selectedOrgUnit,
        projectPostalCode: selectedProjectPostalCode,
        projectLocation: selectedProjectLocation,
        projectMaxDistance: selectedProjectMaxDistance,
        projectStartDate: selectedProjectStartDate,
        projectEndDate: selectedProjectEndDate,
        projectAbsenceThreshold: selectedProjectAbsenceThreshold,
        entryDateAfter: selectedEntryDateAfter,
        lastModifiedDateOlder: selectedLastModifiedDateOlder,
        skillItems: selectedSkillItems,
        searchQuery: userQuery,
        kwePrompt: kwePrompt,
        ragPrompt: ragPrompt,
      });
    } catch (error) {
      console.error('Error saving user settings:', error);
      return savedSearchSettings;
    }
    await fetchSettingsNames();
    setNewSettingName('');
  };
  const sendSearchQuery = async () => {
    setSearchSpinner(true);
    try {
      const { data } = await axios.post(`${API_BASE_URL}/api/search_query`, {
        dataSource: selectedDataSource.map((item) => item.text).join(';'),
        careerLevelFilter: selectedCareerLevel
          .map((item) => item.text)
          .join(';'),
        careerTrackFilter: selectedCareerTrack
          .map((item) => item.text)
          .join(';'),
        searchType: searchTypeOptions[selectedSearchTypeOption].text,
        booleanRag: toggleRag,
        searchMode: searchModeOptions[selectedSearchModeOption].text,
        // If queryType is 'Standard', send 'Full' instead
        queryType: queryTypeOptionsMapping[queryTypeOptions[selectedQueryTypeOption].text],
        maxResults: maxResults,
        startDateFilter:
          'Availability ge ' +
          selectedAvStartDateMin.toISOString().split('T')[0] +
          'T00:00:00Z and Availability le ' +
          selectedAvStartDateMax.toISOString().split('T')[0] +
          'T00:00:00Z',
        staffingStatusFilter: checkedStateStaffingStatus
          .map((item) => item.text)
          .join(';'),
        staffingPriorityFilter: staffingPriorityOptions
          .filter((option) => checkedStateStaffingPriority[option.key])
          .map((option) => `${option.key}`)
          .join(';'),
        orgUnitFilter: selectedOrgUnit.map((item) => item.text).join(';'),
        projectLocation:
          selectedProjectPostalCode !== '' || selectedProjectLocation !== ''
            ? `${selectedProjectPostalCode} ${selectedProjectLocation}`.trim()
            : null,
        geoMaxDistance: selectedProjectMaxDistance,
        projectStartDate:
          selectedProjectStartDate.toISOString().split('T')[0] + 'T00:00:00Z',
        projectEndDate:
          selectedProjectEndDate.toISOString().split('T')[0] + 'T00:00:00Z',
        projectAbsenceThreshold: selectedProjectAbsenceThreshold,
        entryDateFilter:
          'EntryDate ge ' +
          selectedEntryDateAfter.toISOString().split('T')[0] +
          'T00:00:00Z',
        lastModifiedDateFilter:
          'LastModified le ' +
          selectedLastModifiedDateOlder.toISOString().split('T')[0] +
          'T00:00:00Z',
        skillDetails: selectedSkillItems.map((item) => ({
          typeValue: item.typeValue,
          skillValue: item.textValue,
          skillCategory: item.categoryValue,
          skillLevel: `${sliderSkillLevels[item.sliderValue || 0]} knowledge`,
        })),
        kweContextPrompt: kwePrompt,
        ragContextPrompt: ragPrompt,
        query: userQuery,
        userRole: user?.role,
      });

      setResults(data);
      setIsRag(toggleRag);
      if (data) {
        setSearchSpinner(false);
      }
    } catch (error) {
      console.error('Error during search:', error);
      setSearchSpinner(!searchSpinner);
    }
  };

  // Construct search settings component as building block for the main app
  //------------------------------------------------------------------------------------------------------------
  return (
    <div>
      {/* ADVANCED SEARCH */}
      <Stack styles={expanderDivStyle}>
        <Stack horizontal>
          <div className={mergeStyles(searchHeaderDiv)}>
            <h3 className={styles.header}>Advanced search</h3>
          </div>
          <PrimaryButton
            onClick={toggleSearchSettingsExpand}
            styles={buttonStyleExpander}
          >
            Show {expandedSearchSettings ? 'less' : 'more'}
          </PrimaryButton>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            {canViewStatisticEvaluation && (
              <PrimaryButton
                onClick={handleStatsPageNavigation}
                styles={{
                  ...buttonStyleExpander,
                  root: {
                    ...buttonStyleExpander?.root,
                    marginRight: '8px',
                  },
                }}
              >
                Statistics
              </PrimaryButton>
            )}
          </div>
        </Stack>
        {expandedSearchSettings && (
          <div>
            {/* LOAD & RESET */}
            <div className={mergeStyles(searchLoadSettingsDiv)}>
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                tokens={{ childrenGap: 20 }}
                styles={{
                  root: {
                    flexWrap: 'wrap',
                    rowGap: 10,
                  },
                }}
              >
                {/* Linker Bereich: ComboBox und Load/Delete Buttons */}
                <Stack horizontal verticalAlign="center">
                  <CustomComboBox
                    options={searchSettingsNames}
                    selectedOption={selectedSearchSetting}
                    onChange={handleSelectedSearchSetting}
                    placeholder="Select a search setting..."
                  />
                  <Stack horizontal tokens={{ childrenGap: 2 }}>
                    <PrimaryButton
                      onClick={() => {
                        if (selectedSearchSetting?.text !== '') {
                          loadSearchSetting();
                        } else {
                          toast.error('No search setting selected!');
                        }
                      }}
                      styles={buttonStyleLoadDelSavedSetting}
                    >
                      Load
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={() => {
                        if (selectedSearchSetting?.text !== '') {
                          setShowDeleteConfirmationModal(true);
                        } else {
                          toast.error('No search setting selected!');
                        }
                      }}
                      styles={buttonStyleLoadDelSavedSetting}
                    >
                      Delete
                    </PrimaryButton>
                  </Stack>
                  <DeleteConfirmationModal
                    isVisible={showDeleteConfirmationModal}
                    onConfirm={deleteSearchSetting}
                    onCancel={() => setShowDeleteConfirmationModal(false)}
                    message={`${selectedSearchSetting?.text}`}
                  />
                </Stack>

                {/* Rechter Bereich: Neue Einstellung und Save/Reset Buttons */}
                <Stack
                  horizontal
                  tokens={{ childrenGap: 2 }}
                  verticalAlign="center"
                >
                  <TextField
                    placeholder="New setting name..."
                    value={newSettingName}
                    onChange={(_, newValue) =>
                      setNewSettingName(newValue || '')
                    }
                    styles={textFieldNewSearchSetting}
                  />
                  <PrimaryButton
                    onClick={saveSearchSettings}
                    styles={buttonStyleSaveSetting}
                    disabled={!newSettingName.trim()}
                  >
                    Save
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={resetSearchSetting}
                    styles={buttonStyleResetSetting}
                  >
                    Reset
                  </PrimaryButton>
                </Stack>
              </Stack>
            </div>
            {/* SEARCH OPTIONS */}
            <Stack styles={expanderSettingDetailsStyle}>
              <Stack
                horizontal
                verticalAlign="center"
                styles={stackExpanderField}
              >
                <Label styles={labelSettingDetailsStyle}>Search options</Label>
              </Stack>

              <Stack horizontal styles={stackSearchSettings}>
                <div className={mergeStyles(searchSettingsDiv)}>
                  <InfoLabel label="Search type:" infoText={searchTypeTip} />
                  <CustomChoiceGroup
                    options={searchTypeOptions}
                    onChange={handleSearchTypeChange}
                    selectedOption={selectedSearchTypeOption}
                  />
                </div>
                <div className={mergeStyles(searchSettingsDiv)}>
                  <InfoLabel label="Chat results:" infoText={ragTip} />
                  <Toggle
                    checked={toggleRag}
                    onText="On"
                    offText="Off"
                    onChange={handleToggleRag}
                    styles={toggleStyles}
                  />
                </div>

                <div className={mergeStyles(searchSettingsDiv)}>
                  <InfoLabel
                    label="Advanced search options"
                    infoText={furtherAdvancedSearchTip}
                  />
                  <IconButton
                    iconProps={{
                      iconName: expandedAdvancedSearchSettings
                        ? 'ChevronUp'
                        : 'ChevronDown',
                    }}
                    onClick={() =>
                      setExpandedAdvancedSearchSettings(
                        (prevVisible) => !prevVisible
                      )
                    }
                    styles={buttonSettingsDetailsStyle}
                    data-testid="advanced-search-button"
                  />
                </div>
                {expandedAdvancedSearchSettings && (
                  <>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Query type:" infoText={queryTypeTip} />
                      <CustomChoiceGroup
                        options={queryTypeOptions.map((option) => ({
                          ...option,
                          disabled:
                            option.text === 'Standard'
                              ? isStandardDisabled
                              : false,
                        }))}
                        onChange={handleQueryTypeChange}
                        selectedOption={selectedQueryTypeOption}
                      />
                      {isStandardDisabled && (
                        <Label
                          styles={{
                            root: {
                              color: 'gray',
                              fontSize: '12px',
                              fontStyle: 'italic',
                            },
                          }}
                        >
                          Smart search is only available with Semantic Query Type.
                        </Label>
                      )}
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel
                        label="Search mode:"
                        infoText={searchModeTip}
                      />
                      <CustomChoiceGroup
                        options={searchModeOptions}
                        onChange={handleSearchModeChange}
                        selectedOption={selectedSearchModeOption}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel
                        label="Maximum results:"
                        infoText={maxResultsTip}
                      />
                      <SpinButton
                        value={maxResults.toString()}
                        min={0}
                        max={2000}
                        step={1}
                        onChange={handleMaxResultsChange}
                        styles={spinButtonStyle}
                      />
                    </div>
                    {/* KWE context prompt nach Maximum results */}
                    {selectedSearchTypeOption === 1 && (
                      <div className={mergeStyles(searchSettingsDiv)}>
                        <InfoLabel
                          label="Smart search prompt:"
                          infoText={kweContextPromptTip}
                        />
                        <PromptTextfield
                          textValue={kwePrompt}
                          onChange={handleKwePromptChange}
                        ></PromptTextfield>
                      </div>
                    )}
                    {/* RAG context prompt nach KWE context prompt */}
                    {toggleRag && (
                      <div className={mergeStyles(searchSettingsDiv)}>
                        <InfoLabel
                          label="Chat results prompt:"
                          infoText={ragContextPromptTip}
                        />
                        <PromptTextfield
                          textValue={ragPrompt}
                          onChange={handleRagPromptChange}
                        ></PromptTextfield>
                      </div>
                    )}
                    <div className={mergeStyles(searchSettingsPromptDiv)}></div>
                  </>
                )}
              </Stack>
            </Stack>

            {/* DATA SOURCE */}
            <Stack styles={expanderSettingDetailsStyle}>
              <Stack horizontal styles={stackExpanderField}>
                <Label styles={labelSettingDetailsStyle}>Filter options</Label>
              </Stack>
              {expandedDataSourceSettings && (
                <>
                  {/* Erste horizontale Filtergruppe: Source bis Career Track */}
                  <Stack horizontal styles={stackSearchSettings}>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Source:" infoText={dataSourceTip} />
                      <MultiselectDropdown
                        placeholder="Select Source..."
                        options={dataSourceOptions}
                        selectedOptions={selectedDataSource}
                        onChange={handleDataSourceChange}
                        adjustOrder={true}
                        dataTestId="data-source-dropdown"
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel label="Unit:" infoText={orgUnitTip} />
                      <MultiselectDropdown
                        placeholder="Select Unit..."
                        options={orgUnitOptions}
                        selectedOptions={selectedOrgUnit}
                        onChange={handleOrgUnitChange}
                        adjustOrder={false}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel
                        label="Career Level:"
                        infoText={careerLevelTip}
                      />
                      <MultiselectDropdown
                        placeholder="Select Career Level..."
                        options={careerLevelOptions}
                        selectedOptions={selectedCareerLevel}
                        onChange={handleCareerLevelChange}
                        adjustOrder={true}
                        dataTestId="career-level-dropdown"
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel
                        label="Career Track:"
                        infoText={careerTrackTip}
                      />
                      <MultiselectDropdown
                        placeholder="Select Career Track..."
                        options={careerTrackOptions}
                        selectedOptions={selectedCareerTrack}
                        onChange={handleCareerTrackChange}
                        adjustOrder={true}
                        dataTestId="career-track-dropdown"
                      />
                    </div>
                  </Stack>

                  {/* Zweite horizontale Filtergruppe: Availability bis Further Filter */}
                  <Stack horizontal styles={stackSearchSettingsSecondGroup}>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel
                        label="Staffing Status:"
                        infoText={staffingStatusTip}
                      />
                      <MultiselectDropdown
                        placeholder="Select Staffing Status..."
                        options={staffingStatusOptions}
                        selectedOptions={checkedStateStaffingStatus}
                        onChange={handleStaffingStatusChange}
                        adjustOrder={true}
                        dataTestId="staffing-status-dropdown"
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel
                        label="Availability:"
                        infoText={availStartDateTip}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          marginTop: '1px',
                        }}
                      >
                        <div style={{ marginRight: '20px', textAlign: 'left' }}>
                          <span>From:</span>
                          <CustomDatePicker
                            onSelectDate={handleAvStartDateMin}
                            defaultDate={selectedAvStartDateMin}
                          />
                        </div>
                        <div style={{ textAlign: 'left' }}>
                          <span>To:</span>
                          <CustomDatePicker
                            onSelectDate={handleAvStartDateMax}
                            defaultDate={selectedAvStartDateMax}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel
                        label="Entry date:"
                        infoText={entryDateAfterTip}
                      />
                      After or equal to:
                      <CustomDatePicker
                        onSelectDate={handleEntryDate}
                        defaultDate={selectedEntryDateAfter}
                      />
                    </div>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <InfoLabel
                        label="Further filters"
                        infoText={furtherFilterTip}
                      />
                      <IconButton
                        iconProps={{
                          iconName: expandedFilterSettings
                            ? 'ChevronUp'
                            : 'ChevronDown',
                        }}
                        onClick={() =>
                          setExpandedFilterSettings(
                            (prevVisible) => !prevVisible
                          )
                        }
                        styles={buttonSettingsDetailsStyle}
                        data-testid="filter-button"
                      />
                    </div>
                  </Stack>

                  {/* Erweiterte Filter anzeigen, falls aktiviert */}
                  {expandedFilterSettings && (
                    <Stack horizontal styles={stackSearchSettings}>
                      {canViewStaffingPriority && (
                        <div className={mergeStyles(searchSettingsDiv)}>
                          <InfoLabel
                            label="Staffing priority:"
                            infoText={staffingPriorityTip}
                          />
                          <CustomCheckBox
                            options={staffingPriorityOptions}
                            onChange={handleStaffingPriorityChange}
                            checked={checkedStateStaffingPriority}
                          />
                        </div>
                      )}
                      {/* Project location distance */}
                      <div className={mergeStyles(searchSettingsDiv)}>
                        <InfoLabel
                          label="Project location distance:"
                          infoText={projectLocationDistanceTip}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            marginTop: '1px',
                          }}
                        >
                          <div
                            style={{ marginRight: '20px', textAlign: 'left' }}
                          >
                            <span>Postal Code:</span>
                            <TextField
                              value={selectedProjectPostalCode}
                              onChange={(_, newValue) =>
                                setSelectedProjectPostalCode(newValue || '')
                              }
                              placeholder="Enter postal code"
                              styles={geospatialTextfieldStyle}
                            />
                          </div>
                          <div style={{ textAlign: 'left' }}>
                            <span>Project location:</span>
                            <TextField
                              value={selectedProjectLocation}
                              onChange={(_, newValue) =>
                                setSelectedProjectLocation(newValue || '')
                              }
                              placeholder="Enter project location"
                              styles={geospatialTextfieldStyle}
                            />
                          </div>
                        </div>
                        Maximum distance (in km):
                        <SpinButton
                          value={selectedProjectMaxDistance.toString()}
                          min={10}
                          max={1500}
                          step={10}
                          onChange={handleProjectMaxDistanceChange}
                          styles={spinButtonProjectMaxDistanceStyle}
                          disabled={
                            selectedProjectPostalCode.trim() === '' &&
                            selectedProjectLocation.trim() === ''
                          }
                        />
                      </div>
                      {/* Project absences */}
                      <div className={mergeStyles(searchSettingsDiv)}>
                        <InfoLabel
                          label="Project absences:"
                          infoText={projectAbsencesTip}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            marginTop: '1px',
                          }}
                        >
                          <div
                            style={{ marginRight: '20px', textAlign: 'left' }}
                          >
                            <span>Project start date:</span>
                            <CustomDatePicker
                              onSelectDate={handleProjectStartDate}
                              defaultDate={selectedProjectStartDate}
                            />
                          </div>
                          <div style={{ textAlign: 'left' }}>
                            <span>Project end date:</span>
                            <CustomDatePicker
                              onSelectDate={handleProjectEndDate}
                              defaultDate={selectedProjectEndDate}
                            />
                          </div>
                        </div>
                        Absence threshold (in %):
                        <SpinButton
                          value={selectedProjectAbsenceThreshold.toString()}
                          min={0}
                          max={100}
                          step={5}
                          onChange={handleProjectAbsenceThresholdChange}
                          styles={spinButtonProjectMaxDistanceStyle}
                        />
                      </div>
                      {/* Last modified date */}
                      <div className={mergeStyles(searchSettingsDiv)}>
                        <InfoLabel
                          label="Last modified date:"
                          infoText={lastModifiedDateOlderTip}
                        />
                        Before or equal to:
                        <CustomDatePicker
                          onSelectDate={handleLastModifiedDate}
                          defaultDate={selectedLastModifiedDateOlder}
                        />
                      </div>
                    </Stack>
                  )}
                  {/* Basic Data Section */}
                  <Stack horizontal styles={stackSearchSettings}>
                    <div className={mergeStyles(searchSettingsDiv)}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'baseline',
                          gap: '10px',
                        }}
                      >
                        <InfoLabel label="Basic Data:" infoText={skillTip} />
                        <DynamicTextfieldSlider
                          maximumSliderLevel={maximumSkillSliderLevel}
                          sliderLevelMapping={sliderSkillLevels}
                          onSelectionChange={handleSkillSearchChange}
                          itemSelection={selectedSkillItems}
                          removeItem={handleRemoveSkillItem}
                          queryTypeOption={selectedQueryTypeOption}
                          searchTypeOption={selectedSearchTypeOption}
                        />
                      </div>
                    </div>
                  </Stack>
                </>
              )}
            </Stack>
          </div>
        )}
      </Stack>
      {/* TEXTFIELD SEARCH */}

      <Stack wrap styles={expanderDivStyle}>
        <Stack horizontal styles={stackSearchField}>
          <Stack.Item styles={textFieldItem}>
            <TextField
              placeholder="Search for candidates... (English terms only)"
              multiline
              rows={0}
              value={userQuery}
              onChange={(_, newValue) => setUserQuery(newValue || '')}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  sendSearchQuery();
                }
              }}
              styles={textFieldStyleSearch}
            />
          </Stack.Item>
          <Stack.Item styles={buttonItem}>
            <PrimaryButton
              onClick={sendSearchQuery}
              styles={buttonStyleSearch}
              iconProps={{ iconName: 'Search' }}
            >
              {' '}
              Search
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      </Stack>

      {searchSpinner && (
        <Spinner
          size={SpinnerSize.large}
          label="Searching for candidates..."
          styles={spinnerStyle}
        />
      )}
    </div>
  );
};

export default SearchComponent;

import { FC } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { buttonStyleShowCV } from "./react_styles_ui";

interface Props {
  CorpID: string | undefined
};


const CVLink: FC<Props> = ({CorpID}) => {

  // Construct CVLink component
  return (
      <a href={`https://staffingapp.d-fine.local/StaffApp/details/${CorpID}`} target="_blank" rel="noreferrer">
        <PrimaryButton
          disabled={!CorpID}
          styles={buttonStyleShowCV}
        >
          Open CV
        </PrimaryButton>
      </a>
  );
};

export default CVLink;